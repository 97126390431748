'use client'
import Image from "next/image";
import { Inter } from "next/font/google";
import MainLayout from "@/components/Layouts/Main";
import ContactForm from "@/components/Sections/ContactForm/ContactForm";
import HomeSection from "@/components/Sections/HomeSection/HomeSection";
import PricingSection from "@/components/Sections/PricingSection/PricingSection";
import ClientList from "@/components/Sections/ClientList/ClientList";
import CTASection from "@/components/Sections/CTASections/CTASection";
import PartnerList from "@/components/Sections/PartnerList/PartnerList";
import { useEffect, useRef, useState } from "react";
import Testimoni from "@/components/Sections/Testimoni/Testimoni";
import Services from "@/components/Sections/Services/Services";
import { home } from "@/helpers/content/id/home";
import MiniCard from "@/components/MiniCard/MiniCard";
import MiniCardStackThree from "@/components/MiniCardStackThree/MiniCardStackThree";
const inter = Inter({ subsets: ["latin"] });
import { useTranslation } from 'react-i18next';
import { getCookie } from 'cookies-next';

export default function Home() {
  const { t } = useTranslation();
  const language = getCookie('NEXT_LOCALE') || 'id';
  const [isShowBar, setisShowBar] = useState(false)
  const homeRef = useRef()
  const pricingRef = useRef()
  const servicesRef = useRef()
  const servicesMobileRef = useRef([])
  const servicesMainMobileRef = useRef()
  const contactRef = useRef()
  const clientRef = useRef()
  const [nextTab, setnextTab] = useState(0)
  const [nextTabMobile, setnextTabMobile] = useState(0)
  const [servicesElementScroll, setservicesElementScroll] = useState(0)

  useEffect(() => {
    const getLocalStorage = JSON.parse(localStorage.getItem("type"))
    if (getLocalStorage) {
      console.log("local storage",getLocalStorage)
      if (getLocalStorage.index) {
        if (getLocalStorage.scrollTo == "services") {
          setnextTab(getLocalStorage.index)
        }else if(getLocalStorage.scrollTo == "services-mobile"){
          setnextTabMobile(getLocalStorage.index)
        }
        moveToSection(getLocalStorage.scrollTo, getLocalStorage.index)
      }else{
        moveToSection(getLocalStorage.scrollTo)
      }
    }
    localStorage.removeItem("type")
  }, [])
  

  const moveToSection = (type, index = 0) => {
    if (type == "pricingRef") {
      window.scrollTo({top: pricingRef.current.offsetTop, behavior:"smooth" })
    }else if(type == "client"){
      setisShowBar(false)
      window.scrollTo({
        top: clientRef.current.offsetTop, 
        behavior:"smooth"
      })
    }else if (type == "services") {
      setisShowBar(false)
      // setnextTab(index)
      window.scrollTo({
        top: servicesRef.current.offsetTop, 
        behavior:"smooth"
      })
    }
    else if (type == "services-mobile") {
      console.log(index)
      setservicesElementScroll(prev => prev > 0 ? prev : servicesMainMobileRef.current.getBoundingClientRect().top - 100)
      setisShowBar(false)
      setnextTabMobile(index)
      // setTimeout(() => {
      //   window.scrollTo({
      //     top: servicesMainMobileRef.current.getBoundingClientRect().top,
      //     bottom: servicesMainMobileRef.current.getBoundingClientRect().bottom,
      //     behavior:"smooth"
      //   })
      // }, 100);
    }
    else if(type == "article"){
      window.open("https://blog.ledgerowl.com/","_blank")
    }else if(type == "home") { 
      window.scrollTo({
        top: homeRef.current.offsetTop,
        behavior:"smooth"
      })
    }else if(type == "contact") { 
      window.scrollTo({
        top: contactRef.current.offsetTop,
        behavior:"smooth"
      })
    }else if(type == "consultation"){
      window.scrollTo({
        top: contactRef.current.offsetTop,
        behavior:"smooth"
      })
      // window.open("https://api.whatsapp.com/send?phone=6281287889023&text=%20%20Hi!%20Can%20I%20ask%20more%20on%20ledgerowl%20services%20%3f","_blank")
    }
  }

  useEffect(() => {
    // window.scrollTo({
    //   top: servicesElementScroll,
    //   behavior:"smooth"
    // })
  }, [servicesElementScroll])
  

  return (
    <MainLayout 
      setnextTab={setnextTab}
      setnextTabMobile={setnextTabMobile}
      setisShowBar={setisShowBar}
      isShowBar={isShowBar}
      floatingHome={true}
      moveToSection={moveToSection}
    >
      <HomeSection 
        isThreeCard={true}
        homeRef={homeRef} 
        moveToSection={moveToSection}
        home={home}
        cardStackThree = {home.text.in_pict.first_card}
        miniCard = {home.text.in_pict.second_card}
      >
        <div className={`relative w-full lg:w-[740px] flex justify-end h-full`}>
          <div className='md:hidden flex lg:hidden h-full'>
              <Image src={home.imageMobile} />
          </div>
          <div className='md:flex hidden lg:hidden h-full'>
              <Image src={home.imageTablet} />
          </div>
          <div className='md:hidden hidden lg:flex lg:w-[95%] xl:w-full h-full'>
              <Image src={home.imageDesktop} />
          </div>
          <div className='hidden overflow-hidden lg:flex'>
            <div className={`z-20 drop-shadow-xl absolute md:flex md:justify-center top-16 left-5 lg:top-20 md:top-32 md:w-full md:left-0 lg:-left-3 xl:-left-16 flex lg:w-auto items-center`}>
                {
                    <MiniCardStackThree 
                      title={home.text.in_pict.first_card.title} 
                      description={home.text.in_pict.first_card.description} 
                      detail_description={t(home.text.in_pict.first_card.detail_description)}
                      badgeText={home.text.in_pict.first_card.badge_text}
                    />
                  
                }
            </div>
            <div className={`z-20 left-5 md:bottom-20 lg:bottom-20 lg:w-auto md:left-[50%] lg:left-20 drop-shadow-xl absolute flex`}>
                <MiniCard
                    main_title={t(home.text.in_pict.second_card.main_title)}
                    second_title={t(home.text.in_pict.second_card.second_title)}
                    third_title={t(home.text.in_pict.second_card.third_title)}
                    second_description={home.text.in_pict.second_card.second_description}
                    third_description={home.text.in_pict.second_card.third_description}
                />
            </div>
          </div>
          <div className='hidden overflow-hidden md:flex h-full w-full justify-center space-y-32 space-x-32 absolute z-20 py-28 top-0 lg:hidden'>
            <div className={`z-20 drop-shadow-xl absolute md:flex md:justify-center top-16 left-5 lg:top-20 md:top-32 md:w-full md:left-0 lg:-left-16 flex lg:w-auto items-center`}>
                {
                    <MiniCardStackThree 
                      title={home.text.in_pict.first_card.title} 
                      description={home.text.in_pict.first_card.description} 
                      detail_description={home.text.in_pict.first_card.detail_description}
                      badgeText={home.text.in_pict.first_card.badge_text}
                    />
                  
                }
            </div>
            <div className={`z-20 left-5 md:bottom-20 lg:bottom-20 lg:w-auto md:left-[50%] lg:left-20 drop-shadow-xl absolute flex`}>
                <MiniCard
                    main_title={t(home.text.in_pict.second_card.main_title)}
                    second_title={t(home.text.in_pict.second_card.second_title)}
                    third_title={t(home.text.in_pict.second_card.third_title)}
                    second_description={home.text.in_pict.second_card.second_description}
                    third_description={home.text.in_pict.second_card.third_description}
                />
            </div>
          </div>
          <div className='flex overflow-hidden md:hidden h-full w-full absolute z-20 space-y-[86px] px-3 py-16 top-0 border-semantic-negative-900 lg:hidden'>
            <div className={`z-20 drop-shadow-xl absolute md:flex md:justify-center top-16 left-5 lg:top-20 md:top-32 md:w-full md:left-0 lg:-left-16 flex lg:w-auto items-center`}>
                {
                    <MiniCardStackThree 
                      title={home.text.in_pict.first_card.title} 
                      description={home.text.in_pict.first_card.description} 
                      detail_description={home.text.in_pict.first_card.detail_description}
                      badgeText={home.text.in_pict.first_card.badge_text}
                    />
                  
                }
            </div>
            <div className={`z-20 left-5 md:bottom-20 lg:bottom-20 lg:w-auto md:left-[50%] lg:left-20 drop-shadow-xl absolute flex`}>
                <MiniCard
                    main_title={home.text.in_pict.second_cardmain_title}
                    second_title={home.text.in_pict.second_card.second_title}
                    third_title={home.text.in_pict.second_card.third_title}
                    second_description={home.text.in_pict.second_card.second_description}
                    third_description={home.text.in_pict.second_card.third_description}
                />
            </div>
          </div>
        </div>
      </HomeSection>
      <PartnerList />
      <Services 
        lang={language}
        nextTabMobile={nextTabMobile} 
        servicesMainMobileRef={servicesMainMobileRef} 
        servicesElementScroll={servicesElementScroll}
        servicesRef={servicesRef} 
        nextTab={nextTab} 
        setnextTab={setnextTab}
        moveToSection={moveToSection}
      />
      <PricingSection 
        moveToSection={moveToSection}
        pricingRef={pricingRef}/>
      <ClientList clientRef={clientRef} />
      <Testimoni />
      <CTASection />
      <ContactForm contactRef={contactRef}/>
    </MainLayout>
  );
}
